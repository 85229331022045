<template>
  <div class="home">
    <HeaderView />
    <div>
      <img width="100%" src="@/assets/img/about.png" alt="">
    </div>
    <div class="main">
      <div>
        <h1 class="title">园区展示</h1>
        <div>
          <img src="@/assets/img/index-park-ext-1.png" alt="">
        </div>
      </div>
      <div>
        <h1 class="title">车间展示</h1>
        <div>
          <img src="@/assets/img/index-park-ext-2.png" alt="">
        </div>
      </div>
      <div>
        <h1 class="title">设备展示</h1>
        <div>
          <img src="@/assets/img/index-park-ext-3.png" alt="">
        </div>
      </div>
      <div>
        <h1 class="title">核心技术</h1>
        <div>
          <ol>
            <li>
              采用集团公司自主研发的G+G
              电容触摸屏，是目前市场上最
              稳定、最可靠的电容触摸屏。
              最多可达40点触控。属国内领
              先技术水平。
            </li>
            <li>电磁模组采用行业领先的电磁触控技术，书写精准度达到0.2mm，4096级压感</li>
            <li>采用自主研发具有知识产权的节能型大屏背光模组</li>
            <li>集团公司拥有电容触控屏与LCM液晶面板采用无缝隙全贴合技术。技术属国内领先水</li>
            <li>产品采用工业级设计，拥有多项实用新型专利和外观专利</li>
            <li>自有专业软件研发团队，拥有自己的软件著作权</li>
          </ol>
        </div>
      </div>
      <div>
        <h1 class="title">行业前景</h1>
        <div>
          <img width="100%" src="@/assets/img/index-grow1.jpg" alt="">
        </div>
        <div>
          <img width="100%" src="@/assets/img/index-grow2.jpg" alt="">
        </div>
        <div>
          <img width="100%" src="@/assets/img/index-grow3.jpg" alt="">
        </div>
        <div>
          <img width="100%" src="@/assets/img/index-grow4.jpg" alt="">
        </div>
      </div>
      <div>
        <h1 class="title">产品介绍</h1>
        <div style="max-width: 100%;">
          <el-row :gutter="20">
            <el-col :span="24" :offset="0">
              <img width="100%" src="@/assets/img/product1.jpg" alt="">
            </el-col>
            <el-col :span="24" :offset="0">
              <img width="100%" src="@/assets/img/product2.jpg" alt="">
            </el-col>
            <el-col :span="24" :offset="0">
              <img width="100%" src="@/assets/img/product3.jpg" alt="">
            </el-col>
            <el-col :span="24" :offset="0">
              <img width="100%" src="@/assets/img/product4.jpg" alt="">
            </el-col>
            <el-col :span="24" :offset="0">
              <img width="100%" src="@/assets/img/product5.jpg" alt="">
            </el-col>
            <el-col :span="24" :offset="0">
              <img width="100%" src="@/assets/img/product6.jpg" alt="">
            </el-col>
            <el-col :span="24" :offset="0">
              <img width="100%" src="@/assets/img/product7.jpg" alt="">
            </el-col>
          </el-row>
        </div>
      </div>
      <div>
        <h1 class="title">合作案例</h1>
        <div>
          <el-row :gutter="20">
            <el-col :span="24" :offset="0">
              <img width="100%" src="@/assets/img/index-example1.jpg" alt="">
            </el-col>
            <el-col :span="24" :offset="0">
              <img width="100%" src="@/assets/img/index-example2.jpg" alt="">
            </el-col>
            <el-col :span="24" :offset="0">
              <img width="100%" src="@/assets/img/index-example3.jpg" alt="">
            </el-col>
          </el-row>
        </div>
      </div>
      <div>
        <h1 class="title">合作伙伴</h1>
        <div>
          <el-row :gutter="20">
            <el-col :span="24" :offset="0">
              <img width="100%" src="@/assets/img/index-cooperate1.jpg" alt="">
            </el-col>
            <el-col :span="24" :offset="0">
              <img width="100%" src="@/assets/img/index-cooperate2.jpg" alt="">
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <FooterView />
  </div>
</template>

<script>
import HeaderView from '@/components/HeaderView.vue'
import FooterView from '@/components/FooterView.vue'

export default {
  name: 'HomeView',
  components: {
    HeaderView,
    FooterView
  }
}
</script>
<style scoped>
.home .main {
  width: 1240px;
  margin: 0 auto;
}

.home .main .title {
  text-align: center;
}
</style>
