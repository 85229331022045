<template>
  <div class="footer">
    <span>© 2023 szsmarttouch.com 版权所有</span>
    <a class="beian" href="https://beian.miit.gov.cn" target="_blank">
      粤ICP备2023085699号
    </a>
  </div>
</template>

<script>
export default {
  name: 'FooterView',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.footer {
  color: #333;
  background-color: #f1f1f1;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.footer .beian {
  color: #333;
  margin-left: 10px;
  text-decoration: none;
}
</style>
