<template>
  <div id="app">
    <router-view></router-view>
    <el-backtop :right="20" :bottom="80"></el-backtop>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>
<style>
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
  }
</style>