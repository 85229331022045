<template>
  <div class="hello">
    <div class="reg">
      <router-link class="Login" to="/login">登录</router-link>
    </div>
    <div class="nav">
      <ul>
        <li><router-link to="/">首页</router-link></li>
        <li><router-link to="/about">公司介绍</router-link></li>
        <li><router-link to="/team">公司团队</router-link></li>
        <li><router-link to="/honor">企业荣誉</router-link></li>
        <li><router-link to="/product">产品介绍</router-link></li>
        <li><router-link to="/custom">合作客户</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderView',
  props: {
    msg: String
  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

.Login {
  color: #666;
}

.reg {
  position: absolute;
  top: 10px;
  left: 10px;
}

.title {
  display: table;
}

.nav {
  max-width: 1120px;
  margin: 0px 177px 0 auto;
  display: table;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  font-size: 16px;
  text-decoration: none;
  padding: 10px;
  color: #09e438;
}
</style>
